export default {
  group: [
    {
      name: 'personal_data',
      fields: [
        {
          name: 'name_of_legal_entity',
          type: 'text',
          column_type: 'full-row',
        },
        {
          name: 'code_main',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'code_rnokpp',
          type: 'text',
          column_type: 'one-third-row',
        },
      ],
    },
    {
      name: 'address_location',
      fields: [
        {
          name: 'settlement',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'state',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'district',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'street',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'katottg',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'zip',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'house_type',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'house_number',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'building_type',
          type: 'select',
          column_type: 'one-third-row',
        },
        {
          name: 'building_number',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'mail_address',
          type: 'text',
          column_type: 'full-row',
        },
      ],
    },
    {
      name: 'license',
      fields: [
        {
          name: 'license_number',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'license_file_number',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'decision_number',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'date_of_decision',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'inn_of_licensee',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'licensee',
          type: 'text',
          column_type: 'one-third-row',
        },
      ],
    },
    {
      name: 'activity_type',
      fields: [
        {
          name: 'location_of_the_activity',
          type: 'text',
          column_type: 'full-row',
        },
        {
          name: 'activity_kinds',
          type: 'select',
          column_type: 'full-row',
        },
        {
          name: 'record_number',
          type: 'text',
          column_type: 'full-row',
        },
        {
          name: 'registration',
          type: 'date',
          column_type: 'full-row',
        },
      ],
    },
    {
      name: 'head_info',
      fields: [
        {
          name: 'head_full_name',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'experience_of_the_supervisor',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'qualification_of_the_supervisor',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'diploma_series',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'diploma_number',
          type: 'text',
          column_type: 'one-third-row',
        },
      ],
    },
    {
      name: 'subject_info',
      fields: [
        {
          name: 'type_of_activity',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'activity_kinds',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'date_of_state_registration',
          type: 'date',
          column_type: 'one-third-row',
        },
        {
          name: 'subject_status_description',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'financial_support_of_the_tour_operator',
          type: 'date',
          column_type: 'one-third-row',
        },
        {
          name: 'availability_information',
          type: 'text',
          column_type: 'full-row',
        },
      ],
    },
    {
      name: 'insurance_block',
      fields: [
        {
          name: 'name_of_insurance',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'contract_from_insurance',
          type: 'file',
          column_type: 'one-third-row',
        },
      ],
    },
    {
      name: 'statistical_block',
      fields: [
        {
          name: 'statistical_calls',
          type: 'file',
          column_type: 'one-third-row',
        },
      ],
    },
  ],
};
